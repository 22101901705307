import { AfterViewInit, Component, ElementRef, Input, ViewChild, afterNextRender } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, first } from 'rxjs';
import Player from '@vimeo/player';

// https://developer.vimeo.com/player/sdk/basics

/**
 * Used in {@link VideoComponent}. Component to reproduce YouTube videos.
 */
@Component({
  selector: 'ush-vimeo-player',
  templateUrl: './vimeo-player.component.html',
  styleUrls: ['./vimeo-player.component.scss']
})
export class VimeoPlayerComponent {
  @Input()
  autplay = false;

  /**
   * @docsNotRequired
   */
  _url: string;

  @Input()
  set url(value: string) {
    if (this.autplay) value = this.attachAutplayParamsToUrl(value);
    this._url = value;
    this.vimeoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  get url(): string {
    return this._url;
  }

  vimeoPlayer: Player;

  /**
   *Setted with the {@link url}.
   */
  vimeoUrl: SafeResourceUrl;

  /**
   *  @docsNotRequired
   */
  @Input()
  title: string;

  @ViewChild('vimeoiframe')
  vimeoiframe: ElementRef;

  playerReady = new BehaviorSubject(false);

  scriptElement: HTMLScriptElement;

  constructor(private sanitizer: DomSanitizer) {
    afterNextRender(() => {
      this.loadVimeoScript();
      this.loadIframePlayer();
    });
  }

  loadVimeoScript() {
    const foundScript = document.querySelector('#vimeo-script') as HTMLScriptElement;
    if (foundScript) {
      this.scriptElement = foundScript;
    } else {
      const VimeoAPI = 'https://player.vimeo.com/api/player.js';
      const VimeoScript = document.createElement('script');
      VimeoScript.id = 'vimeo-script';
      VimeoScript.setAttribute('async', '');
      VimeoScript.src = VimeoAPI;
      document.head.appendChild(VimeoScript);
      this.scriptElement = VimeoScript;
    }
  }

  loadIframePlayer() {
    if (this.scriptElement.getAttribute('data-ready')) this.setPlayer();
    else
      this.scriptElement.onload = () => {
        this.scriptElement.setAttribute('data-ready', 'true');
        this.setPlayer();
      };
  }

  setPlayer() {
    this.vimeoPlayer = new Player(this.vimeoiframe.nativeElement);
    this.playerReady.next(true);
  }

  play() {
    if (this.playerReady.value === true) {
      this.vimeoPlayer.play();
    } else {
      this.playerReady.pipe(first(val => val)).subscribe(() => {
        this.vimeoPlayer.play();
      });
    }
  }

  pause() {
    if (this.playerReady.value === true) {
      this.vimeoPlayer.pause();
    } else {
      this.playerReady.pipe(first(val => val)).subscribe(() => {
        this.vimeoPlayer.pause();
      });
    }
  }

  mute() {
    if (this.playerReady.value === true) {
      this.vimeoPlayer.setMuted(true);
    } else {
      this.playerReady.pipe(first(val => val)).subscribe(() => {
        this.vimeoPlayer.setMuted(true);
      });
    }
  }

  unmute() {
    if (this.playerReady.value === true) {
      this.vimeoPlayer.setMuted(false);
    } else {
      this.playerReady.pipe(first(val => val)).subscribe(() => {
        this.vimeoPlayer.setMuted(false);
      });
    }
  }

  attachAutplayParamsToUrl(url: string) {
    let union = url.includes('?') ? '&' : '?';
    if (!url.includes('autoplay=')) {
      url = url + union + 'autoplay=1';
      union = '&';
    }
    if (!url.includes('mute=')) {
      url = url + union + 'mute=1';
    }
    if (!url.includes('autopause=')) {
      url = url + union + 'autopause=0';
    }
    return url;
  }
}
