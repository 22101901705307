<iframe
  #vimeoiframe
  [src]="vimeoUrl"
  style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
  frameborder="0"
  allow="autoplay; picture-in-picture"
  allowfullscreen
  title="{{ title }}"
>
</iframe>
