import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoComponent } from './video/video.component';
import { SharedUtilsTranslationModule } from '@usheru-hff/shared/utils-translation';
import { VimeoPlayerComponent } from './video/vimeo-player/vimeo-player.component';
import { YouTubePlayer } from '@angular/youtube-player';

@NgModule({
  imports: [CommonModule, SharedUtilsTranslationModule, YouTubePlayer],
  declarations: [VideoComponent, VimeoPlayerComponent],
  exports: [VideoComponent]
})
export class SharedUiVideoModule {}
